<template>
  <div>
    <div
      v-if="userImg && bracelet_id"
      class="d-flex justify-content-center mt-2 mb-1"
    >
      <b-row>
        <b-col cols="12">
          <b-img
            thumbnail
            rounded
            :src="userImg"
            width="200"
          />
          <p style="color:white;" class="d-flex flex-column">
            <span
              v-if="customerName"
              style="color:white;"
            >
              {{ customerName }}
            </span>
          </p>
        </b-col>
        <b-col cols="12">
          <p style="color:orange;" class="d-flex flex-column">
            <span style="color:white;">
              ¿La dirección de entrega es la correcta?
            </span>
            <span v-if="customerAddress">
              {{ customerAddress.street }} {{ customerAddress.ext_number }},
              CP {{ customerAddress.postal_code }}, {{ customerAddress.city }},
              {{ customerAddress.state }}, {{ customerAddress.country }}
            </span>
          </p>
        </b-col>
      </b-row>
    </div>
    <div
      v-if="nfcStatus"
      class="d-flex justify-content-center"
    >
      <b-badge variant="light-warning my-1">
        {{ nfcStatus }}
      </b-badge>
    </div>

    <div v-if="bannedItems.length">
      <h5 class="text-warning">
        Retira los siguientes productos de la orden para continuar.
      </h5>
      <product-card
        v-for="product in bannedItems"
        :key="`item-${product.id}`"
        :product="product"
      />
      <b-button
        class="mb-2"
        block
        @click="removeBannedProductsFromCart(bannedItems)"
      >
        Retirar productos
      </b-button>
    </div>

    <b-row>
      <b-col
        cols="12"
        class="mb-2 d-flex justify-content-center"
      >
        <b-button
          v-if="!bracelet_id"
          block
          variant="primary"
          @click="readNfc()"
        >
          Cliente registrado
        </b-button>
        <div class="space">
        </div>
        <b-button
          v-if="!bracelet_id"
          block
          variant="secondary"
          class="new-customer-button"
          @click="inputAddress()"
        >
          <h5 class="mb-0">
            Sin registrar
          </h5>
        </b-button>
        <b-button-group
          v-else
          class="w-100"
        >
          <b-button
            variant="danger"
          >
            Cancelar
          </b-button>
          <b-button
            variant="success"
            :disabled="bannedItems.length > 0"
            @click="completeOrderWithBracelet('cash')"
          >
            Finalizar
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BImg,
  BButtonGroup,
  BBadge,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'
import ProductCard from './ProductCard.vue'

export default {
  components: {
    BButton,
    BImg,
    BButtonGroup,
    ProductCard,
    BBadge,
  },
  data() {
    return {
      bracelet_id: null,
      nfcStatus: null,
      userImg: null,
      customerAddress: null,
      customerName: null,
      bannedItems: [],
    }
  },
  computed: {
    ...mapGetters('pos', [
      'cartTotal',
      'cartTotalProducts',
      'cart',
    ]),
  },
  watch: {
    bracelet_id: debounce(function searchQuery(query) {
      if (query) {
        this.fetchWalleatByEnc(query)
          .then(response => {
            this.userImg = response.data.logo
            this.customerAddress = response.data.delivery_location
            this.customerName = response.data.name
          })
      } else {
        this.userImg = null
        this.customerAddress = null
        this.customerName = null
      }
    }, 250),
  },
  mounted() {
  },
  methods: {
    ...mapActions('walleats', [
      'fetchWalleatByEnc',
    ]),
    async readNfc() {
      this.nfcStatus = 'Scanning'
      try {
        // eslint-disable-next-line
        const ndef = new NDEFReader()
        await ndef.scan()

        ndef.addEventListener('readingerror', () => {
          this.nfcStatus = 'Argh! Cannot read data from the NFC tag. Try another one?'
        })

        // eslint-disable-next-line
        ndef.addEventListener('reading', ({ message, serialNumber }) => {
          // eslint-disable-next-line
          const audio = new Audio(require('@/assets/sounds/Activate.wav'))
          audio.play()
          if (navigator.vibrate) {
            navigator.vibrate(200)
          }
          const textDecoder = new TextDecoder()
          this.bracelet_id = textDecoder.decode(message.records[0].data).substring(textDecoder.decode(message.records[0].data).lastIndexOf('=') + 1)
          this.nfcStatus = null
        })
      } catch (error) {
        this.nfcStatus = 'Error: No NFC'
      }
    },
    ...mapActions('orders', [
      'addOrder',
      'dispatchPresaleOrder',
    ]),
    ...mapActions('pos', [
      'emptyCart',
    ]),
    ...mapMutations('pos', [
      'deleteProductFromCarts',
    ]),
    inputAddress() {
      this.$bvModal.hide('scan-customer-card')
      this.$bvModal.show('change-delivery-address')
    },
    completeOrderWithBracelet(paymentType) {
      this.loading = true
      const orderReady = {
        payment_type: paymentType,
        bracelet_number: this.bracelet_id,
        store_id: this.cart[0].store_attributes.id,
        order_store_products_attributes: this.cart.map(product => ({
          store_product_id: product.id,
          units: product.units,
        })),
        order_payments_attributes: [
          {
            payment_type: paymentType,
            amount: this.cartTotal
          },
        ],
      }

      this.dispatchPresaleOrder({ order: orderReady, orderType: 'sell', isRefund: 'false' })
        .then(() => {
          this.bracelet_id = null
          // eslint-disable-next-line
          const audio = new Audio(require('@/assets/sounds/Success.wav'))
          audio.play()
          this.$swal({
            text: 'Venta exitosa!',
            title: 'Tu orden será procesada en breve',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.emptyCart()
          this.$bvModal.hide('select-payment-method')
          this.$bvModal.hide('complete-codi-payment')
          this.$bvModal.hide('scan-customer-card')
          this.loading = false
          this.cash = null
          this.$emit('prev-step')
        }).catch(error => {
          // eslint-disable-next-line
          const audio = new Audio(require('@/assets/sounds/Denied.wav'))
          audio.play()
          this.$bvModal.hide('select-payment-method')
          this.$bvModal.hide('complete-codi-payment')
          this.loading = false
          if (navigator.vibrate) {
            navigator.vibrate(200)
          }
          this.bannedItems = error.response.data.banned_items
        })
    },
    removeBannedProductsFromCart(products) {
      products.forEach(product => {
        this.cart.forEach(prod => {
          if (prod.product_attributes.id === product.id) {
            this.deleteProductFromCarts(prod)
          }
        })
      })
      this.bannedItems = []
      if (this.cartTotalProducts === 0) {
        this.$emit('prev-step')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.new-customer-button {
  background-color: #444c66;
}
.space {
  width: 25px;
  height: auto;
  display: inline-block;
}

</style>

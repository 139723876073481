<template>
  <div style="height: inherit">

    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ totalProducts }} resultados
              </div>
            </div>
            <div class="view-options d-flex">
              <!-- Item View Radio Button Group  -->
              <b-button
                class="mr-1"
                variant="primary"
                @click="handlePurchase"
              >
                <span>Finalizar compra</span>
                <feather-icon
                  icon="ShoppingCartIcon"
                  class="ml-50"
                  size="15"
                  :badge="cartTotalProducts"
                />
              </b-button>
              <b-modal
                v-if="cartTotalProducts>0"
                id="finish-purchase"
                title="Resumen de compra"
                class="modal-content"
                cancel-variant="outline-danger"
                ok-variant="success"
                ok-title="Continuar"
                cancel-title="Cancelar"
                @ok="$bvModal.show('select-payment-method')"
              >
                <div class="cart-modal">
                  <cart />
                </div>
                <b-row>
                  <b-col>
                    <order-info-online />
                  </b-col>
                </b-row>
              </b-modal>
              <!-- Scan customer card -->
              <b-modal
                id="scan-customer-card"
                title="Registra al cliente..."
                class="modal-content"
                ok-variant="outline-warning"
                cancel-variant="outline-danger"
                ok-title="Regresar"
                cancel-title="Cancelar"
                @ok="$bvModal.show('select-payment-method')"
              >
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-2 d-flex justify-content-center"
                  >
                    <android-nfc-chrome-presale />
                  </b-col>
                </b-row>
              </b-modal>
              <!-- Select Payment method -->
              <b-modal
                id="select-payment-method"
                title="Selecciona el método de pago"
                class="modal-content"
                ok-variant="outline-warning"
                cancel-variant="outline-danger"
                ok-title="Regresar"
                cancel-title="Cancelar"
                @ok="$bvModal.show('finish-purchase')"
              >
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-2 d-flex justify-content-center"
                  >
                    <b-button
                      variant="outline-primary"
                      class="codi-button-products"
                      :disabled.sync="loading"
                      @click="showCodiPaymentModal()"
                    >
                      <b-img
                        :src="require('@/assets/images/logo/CODI.svg')"
                        width="90"
                      />
                      <h5 class="mb-0">
                        Pago con Codi
                      </h5>
                    </b-button>
                    <div class="space">
                    </div>
                    <b-button
                      variant="outline-primary"
                      size="sm"
                      class="cash-button-products"
                      :disabled.sync="loading"
                      @click="showScanCustomerModal()"
                    >
                      <b-img
                        :src="require('@/assets/images/logo/CASH.svg')"
                        width="80"
                      />
                      <b-spinner
                        v-if="loading"
                        large
                      />
                      <h5 class="pt-2">
                        Pago con Efectivo
                      </h5>
                    </b-button>
                  </b-col>
                </b-row>
              </b-modal>
              <!-- Finish CoDi payment -->
              <b-modal
                id="complete-codi-payment"
                title="Pago con CoDi"
                no-stacking
                no-close-on-esc
                no-close-on-backdrop
                hide-header-close
                :disabled.sync="loading"
              >
                <validation-observer
                  ref="accountCelRules"
                  tag="form"
                >
                  <b-row>
                    <b-col
                      class="mb-2 d-flex flex-column justify-content-center align-items-center"
                      cols="12"
                    >
                      <b-img
                        :src="require('@/assets/images/logo/CODI.svg')"
                        width="150"
                      />
                      <h5 class="mb-0">
                        Número de teléfono
                      </h5>
                      <small class="text-muted">Agrega tu número de teléfono para confirmar la operación</small>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label-for="v-cel_number"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Celular"
                          rules="required|digits:10"
                        >
                          <b-form-input
                            id="v-cel_number"
                            v-model="order.cel_number"
                            placeholder="Celular a 10 dígitos"
                            size="lg"
                            type="number"
                            class="text-center"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-observer>
                <b-col
                  cols="12"
                  class="d-flex justify-content-center align-items-center"
                >
                  <b-button
                    v-b-modal.codi-info
                    variant="link"
                  >
                    ¿Cómo activar CoDi? <em class="fas fa-info-circle" />
                  </b-button>
                </b-col>
                <template #modal-footer>
                  <b-button
                    variant="outline-danger"
                    @click="$bvModal.show('select-payment-method')"
                  >
                    Cancelar
                  </b-button>
                  <div>
                    <b-button
                      :disabled="!(order.cel_number && String(order.cel_number).length === 10) || loading"
                      :variant="!(order.cel_number && String(order.cel_number).length === 10) ? 'outline-primary' : 'success'"
                      @click="completeOnlineOrder('codi')"
                    >
                      <b-spinner
                        v-if="loading"
                        small
                      />
                      OK
                    </b-button>
                  </div>
                </template>
              </b-modal>

              <b-modal
                id="codi-info"
                title="Conoce como activar CoDi"
                ok-only
                ok-title="Cancelar"
                ok-variant="secondary"
              >
                <b-row class="d-flex justify-content-center">
                  <b-col sm="auto" class="d-flex flex-column">
                    <b-button
                      href="https://youtu.be/Ywx-aXuqqUI"
                      target="_blank"
                      variant="primary"
                      class="mb-1"
                    >
                      BBVA
                    </b-button>
                    <b-button
                      href="https://youtu.be/UP1RvwyvmLA"
                      target="_blank"
                      variant="primary"
                      class="mb-1"
                    >
                      Citibanamex
                    </b-button>
                    <b-button
                      href="https://youtu.be/pfAvXmyZ8xQ"
                      target="_blank"
                      variant="primary"
                      class="mb-1"
                    >
                      Santander
                    </b-button>
                    <b-button
                      href="https://youtu.be/ELo20ro2GyE"
                      target="_blank"
                      variant="primary"
                      class="mb-1"
                    >
                      Banorte
                    </b-button>
                    <b-button
                      href="https://www.scotiabank.com.mx/servicios-bancarios/codi.aspx"
                      target="_blank"
                      variant="primary"
                      class="mb-1"
                    >
                      Scotiabank
                    </b-button>
                    <b-button
                      href="https://www.hsbc.com.mx/content/dam/hsbc/mx/documents/digital/hsbc-mexico/registro_codi_hsbc_mexico.pdf"
                      target="_blank"
                      variant="primary"
                      class="mb-1"
                    >
                      HSBC
                    </b-button>
                    <b-button
                      href="https://www.banregio.com/codi.php"
                      target="_blank"
                      variant="primary"
                      class="mb-1"
                    >
                      Banregio
                    </b-button>
                    <b-button
                      href="https://www.bancoppel.com/modal_bcopp/guia_codi_activacion_movil.html"
                      target="_blank"
                      variant="primary"
                      class="mb-1"
                    >
                      Bancoppel
                    </b-button>
                    <b-button
                      href="https://www.bancoazteca.com.mx/conocenos/uso-de-codi.html"
                      target="_blank"
                      variant="primary"
                      class="mb-1"
                    >
                      Banco Azteca
                    </b-button>
                  </b-col>
                </b-row>
              </b-modal>
              <b-modal
                id="change-delivery-address"
                title="Introduce nueva dirección"
                class="modal-content"
                ok-variant="success"
                cancel-variant="outline-warning"
                ok-title="Finalizar"
                cancel-title="Regresar"
                @ok="registerDeliveryAddress()"
                @cancel="$bvModal.show('finish-purchase')"
              >
                <!-- <address-form-model
                  info-type
                  @getAddress="setAddressForEdit($event)"
                /> -->
                <google-address-form @setAddress="setAddressForEdit($event)"></google-address-form>
                <b-spinner
                  v-if="loading"
                  large
                />
              </b-modal>
              <b-button
                class="mr-1"
                variant="primary"
                @click="$bvModal.show('scanner')"
              >
                <i class="fas fa-barcode" />
              </b-button>
              <b-modal id="scanner">
                <StreamBarcodeReader
                  @decode="onDecode"
                />
              </b-modal>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar my-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="filters.q"
              placeholder="Buscar producto"
              class="search-product"
            />
            <b-input-group-append v-if="filters.q">
              <b-button
                variant="outline-warning"
                @click="clearSearchbarAndResetSearch()"
              >
                Borrar
              </b-button>
            </b-input-group-append>
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!-- Phone credits and services
    <div>
      <services-grid-online />
    </div> -->

    <!-- Prodcuts -->
    <section :class="itemView">
      <b-card
        v-for="product in products"
        :key="product.id"
        class="ecommerce-card"
        no-body
      >
        <div
          class="mt-1 text-center d-flex justify-content-center px-1"
          :class="{ 'mb-1' : itemView === 'list-view'}"
        >
          <b-link :to="{ name: 'product-view', params: { id: product.product_attributes.id } }">
            <b-img
              :alt="`${product.product_attributes.name}-${product.id}`"
              fluid
              rounded
              :src="`${product.product_attributes.logo}`"
              :width="itemView === 'list-view' ? 110 : 150"
            />
          </b-link>
        </div>

        <!-- Product Details -->
        <b-card-body>
          <div class="item-wrapper">
            <div class="item-rating">
              <nutri-score
                v-if="product.product_attributes.nutritional_info"
                :nutritional-info="product.product_attributes.nutritional_info"
                :class="{ 'mt-1' : itemView === 'list-view'}"
              />
            </div>
          </div>
          <h6 class="item-name">
            <b-link
              class="text-body"
              :to="{ name: 'product-view', params: { id: product.product_attributes.id } }"
            >
              {{ product.product_attributes.name }}
            </b-link>
            <b-link class="text-muted">
              {{ product.product_attributes.variant }}
            </b-link>
          </h6>
          <div class="item-wrapper">
            <div class="item-cost">
              <h5 class="item-price text-success d-flex justify-content-between">
                <span class="mr-1">Precio</span> ${{ product.unit_price }}
              </h5>
            </div>
          </div>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <!-- 🐔 -->
          </div>
          <b-button
            variant="primary"
            tag="a"
            class="btn-cart"
            @click="addProductToCart({ data: [{ ...product }] })"
          >
            <feather-icon
              icon="ShoppingCartIcon"
              class="mr-50"
            />
            <span>Añadir a carrito</span>
          </b-button>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <section v-if="totalProducts > filters.perPage">
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalProducts"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <shop-left-filter-sidebar
        :filters="filters"
        :categories="categories"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

<script>
import _ from 'underscore'
import {
  BRow, BCol, BInputGroup, BInputGroupAppend,
  BFormInput, BCard, BCardBody, BLink, BImg, BButton, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import { watch } from '@vue/composition-api'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import NutriScore from '@/@core/components/NutriScore.vue'
import Cart from '@/views/pos/Cart.vue'
import ShopLeftFilterSidebar from '@/views/e-commerce/e-commerce-shop/ECommerceShopLeftFilterSidebar.vue'
import { useShopFiltersSortingAndPagination, useShopUiGrid, useShopRemoteData } from '@/views/stores/useStoreProducts'
import { useEcommerceUi } from '@/views/e-commerce/useEcommerce'
import { StreamBarcodeReader } from 'vue-barcode-reader'
import GoogleAddressForm from '@/@core/components/GoogleAddressForm.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
// import ServicesGridOnline from '@/views/pos/ServicesGridOnline.vue'
import OrderInfoOnline from '@/views/pos/OrderInfoOnline.vue'
import AndroidNfcChromePresale from '@/views/e-commerce/e-commerce-checkout/AndroidNfcChromePresale.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BPagination,
    GoogleAddressForm,
    ValidationProvider,
    ValidationObserver,
    // ServicesGridOnline,

    // SFC
    ShopLeftFilterSidebar,

    // Walleat
    NutriScore,
    AndroidNfcChromePresale,

    StreamBarcodeReader,
    Cart,
    OrderInfoOnline,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(localStorage.getItem('userData')),
      profileFile: null,
      loading: false,
      required,
      paymentMethod: 'cash',
      email,
      isDeviceAndroid: false,
      order: {
        cel_number: null,
      },
    }
  },
  setup() {
    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useShopFiltersSortingAndPagination()

    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    const {
      itemView, itemViewOptions, totalProducts,
    } = useShopUiGrid()

    const {
      products, fetchOnlineStoreProducts, categories, fetchCategories,
    } = useShopRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    // eslint-disable-next-line
    const fetchShopProducts = _.debounce(function() {
      if (/^\d*$/.test(filters.value.q) && filters.value.q !== null && filters.value.q !== '') {
        const barcodeWithOutLastDigit = filters.value.q.substring(0, filters.value.q.length - 1)
        fetchOnlineStoreProducts({
          by_sku: Number(barcodeWithOutLastDigit) || null,
          by_category: filters.value.categories || null,
          by_active_status: true,
          by_nutritional_info: filters.value.nutriScore || null,
          by_store: router.currentRoute.params.store_id,
          meta: {
            pagination: {
              page: filters.value.page,
              per_page: filters.value.perPage,
            },
          },
        })
          .then(response => {
            products.value = []
            products.value = response.data
            totalProducts.value = response.meta.pagination.total_objects
          })
      } else if (filters.value.q !== null && filters.value.q !== '') {
        fetchOnlineStoreProducts({
          by_name: filters.value.q || null,
          by_category: filters.value.categories || null,
          by_active_status: true,
          by_nutritional_info: filters.value.nutriScore || null,
          by_store: router.currentRoute.params.store_id,
          meta: {
            pagination: {
              page: filters.value.page,
              per_page: filters.value.perPage,
            },
          },
        })
          .then(response => {
            products.value = []
            products.value = response.data
            totalProducts.value = response.meta.pagination.total_objects
          })
      } else if (filters.value.q === null || filters.value.q === '') {
        fetchOnlineStoreProducts({
          by_name: filters.value.q || null,
          by_category: filters.value.categories || null,
          by_active_status: true,
          by_nutritional_info: filters.value.nutriScore || null,
          by_store: router.currentRoute.params.store_id,
          meta: {
            pagination: {
              page: filters.value.page,
              per_page: filters.value.perPage,
            },
          },
        })
          .then(response => {
            products.value = []
            products.value = response.data
            totalProducts.value = response.meta.pagination.total_objects
          })
      }
    }, 500)

    const fetchProductsCategories = () => {
      fetchCategories({
        by_active_status: true,
        by_store: router.currentRoute.params.store_id,
        meta: {
          pagination: {
            per_page: 10000,
          },
        },
      })
        .then(response => {
          categories.value = response.data
        })
    }

    fetchShopProducts()
    fetchProductsCategories()

    watch([filters, sortBy], () => {
      fetchShopProducts()
    }, {
      deep: true,
    })

    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUiGrid
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,

      // useShopRemoteData
      products,
      categories,
      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
  computed: {
    ...mapGetters('pos', [
      'cartTotal',
      'cart',
      'cartTotalProducts',
    ]),
  },
  mounted() {
    const { filters } = useShopFiltersSortingAndPagination()
    const { products } = useShopRemoteData()
    const { totalProducts } = useShopUiGrid()
    const ua = navigator.userAgent.toLowerCase()
    const isAndroid = ua.indexOf('android') > -1
    // && ua.indexOf('mobile')
    if (isAndroid) {
      this.isDeviceAndroid = true
    }
    this.fetchEmidaProducts()
    this.fetchOnlineStoreProducts({
      by_active_status: true,
      by_store: router.currentRoute.params.store_id,
      meta: {
        pagination: {
          page: filters.value.page,
          per_page: filters.value.perPage,
        },
      },
    })
      .then(resp => {
        products.value = resp.data
        totalProducts.value = resp.meta.pagination.total_objects
      })
  },
  methods: {
    ...mapActions('pos', ['addProductToCart']),
    ...mapActions('stores', ['fetchOnlineStoreProducts']),
    ...mapActions('pos', ['emptyCart']),
    ...mapActions('orders', [
      'dispatchPresaleOrder',
    ]),
    ...mapActions('emidaProducts', ['fetchEmidaProducts']),
    ...mapActions('users', ['updateCustomerAddress']),
    ...mapMutations('users', ['updateUserData']),
    handlePurchase() {   
      this.$bvModal.show('finish-purchase')
    },
    prevStep() {
      this.$emit('prev-step')
    },
    showScanCustomerModal() {
      this.$bvModal.show('scan-customer-card')
      this.$bvModal.hide('select-payment-method')
    },
    setAddressForEdit(x) {
      this.address_attributes = x
    },
    clearSearchbarAndResetSearch() {
      this.filters.q = ''
      this.products = []
    },
    onDecode(result) {
      this.filters.q = result
      this.$bvModal.hide('scanner')
    },
    showCodiPaymentModal() {
      this.$bvModal.hide('select-payment-method')
      this.$bvModal.show('complete-codi-payment')
    },
    eliminarDiacriticos(text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    completeOrderWithOutBracelet(paymentType) {
      this.loading = true
      const orderReady = {
        store_id: this.$router.currentRoute.params.store_id,
        address_attributes: this.address_attributes,
        order_store_products_attributes: this.cart.map(product => ({
          store_product_id: product.id,
          units: product.units,
        })),
        order_payments_attributes: [
          {
            payment_type: paymentType,
            amount: this.cartTotal
          },
        ],
      }

      this.dispatchPresaleOrder({ order: orderReady, orderType: 'sell', isRefund: 'false' })
        .then(() => {
          this.$swal({
            text: 'Venta exitosa!',
            title: 'Tu orden será procesada en breve',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.emptyCart()
          this.$bvModal.hide('select-payment-method')
          this.$bvModal.hide('complete-codi-payment')
          this.loading = false
        })
        .catch(error => {
          this.$bvModal.hide('select-payment-method')
          this.$bvModal.hide('complete-codi-payment')
          this.loading = false
          const baseMsg = error.response.data.messages?.base ?? null
          if (baseMsg != null) {
            this.$swal({
              title: 'ERROR!',
              text: `${error.response.data.messages.base[0]}`,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
    registerDeliveryAddress() {
      this.loading = true
      this.completeOrderWithOutBracelet('cash')
      this.$bvModal.hide('change-delivery-address')
    },
    changeDeliveryAddress() {
      this.address_attributes.id = this.optionsLocal.customer.address.id
      this.updateCustomerAddress({
        id: this.optionsLocal.customer.id,
        customer: {
          address_attributes: this.address_attributes,
        },
      })
        .then(() => {
          this.$bvModal.show('select-payment-method')
        })
        .catch(error => {
          const baseMsg = error.response.data.messages?.base ?? null
          if (baseMsg != null) {
            this.$swal({
              title: 'ERROR!',
              text: `${error.response.data.messages.base[0]}`,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">

@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>

.modal-content {
  background-color: #1B243D;
  .modal-body {
    padding: 0rem;
    backdrop-filter: #1B243D;
    z-index: 100;
  }
}
.cart-modal {
  max-height: 500px;
  overflow-y: auto;
  border-radius: 10px 10px 10px 10px;
}
.card-img-top {
  max-width: 225px;
}
.badge-primary {
  background-color: #6c757d;
}

.space {
  width: 15px;
  height: auto;
  display: inline-block;
}

.codi-button-products {
  background-color: #444c66;
}

.cash-button-products {
  background-color: #444c66;
}
</style>
